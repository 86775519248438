<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card>
          <v-toolbar
            dense
            color="teal"
            height="40"
            dark
          >
            Resultados de la visita
            <v-spacer />

            <v-btn
              x-small
              text
              @click="mostrarprospecto"
            >
              Prospecto
            </v-btn>

            <v-btn
              x-small
              text
              @click="mostrarcliente"
            >
              Cliente
            </v-btn>
          </v-toolbar>
        
          <v-card
            class="rounded-lg"
            elevation="2"
            color="blue-grey lighten-5"
          >
            <v-row class="mx-1">
              <v-col>
                <h3><v-icon>mdi-store</v-icon>OPORTUNIDAD DE:</h3>
                <v-text-field
                  v-model="nomcont"
                  label="Contacto"
                  readonly
                  
                  class="mb-1"
                  hide-details
                />
                <v-text-field
                  v-model="empresa"
                  label="Empresa"
                  readonly
                  
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card>


          <v-row justify="center">
            <v-col
              v-if="vercliente"
              cols="12"
            >
              <reportarcliente />
            </v-col>
            <v-col
              v-if="verprospecto"
              cols="12"
            >
              <reportarprospecto />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import reportarcliente from '@/views/crm/oportunidades/seguimientooport/demo/reportarcliente.vue'
import reportarprospecto from '@/views/crm/oportunidades/seguimientooport/demo/reportarprospecto.vue'
export default {
  components: {
    reportarprospecto,
    reportarcliente
  },
  data: () => ({
    nomcont:'',
    empresa:'',
    verprospecto: true,
    vercliente: false,
  }),
  methods:{
    mostrarprospecto(){
      this.verprospecto = true
      this.vercliente = false
    },
    mostrarcliente(){
      this.vercliente = true
      this.verprospecto  = false
    },
  }
}
</script>
